<script lang="ts">
  import ProjectTabs from "../components/ProjectTabs.svelte";
  import { pageTitle, isAdmin, isSuperAdmin } from "../stores/store";
  import { onMount } from "svelte";
  import EditableDate from "../components/EditableDate.svelte";
  import {
    hasKeys,
    deletePublicKey,
    restorePublicKey,
  } from "../services/encryption";
  import page from "page";
  import Loading from "../components/Loading.svelte";
  import {
    deleteProject,
    getProject,
    updateProject,
    getCSV,
    importData,
    importProject,
    checkImportStatus
  } from "../services/project";
  import { text_area_resize } from "../utils/autoresize_textarea";

  export let projectId;
  let project: Record<string, any> | undefined;
  let url;
  $: url = import.meta.env.VITE_SITE_PREFIX + project?.slug;
  let errors: Record<string, any> = {};

  let hasPublicKey = true;
  let loadedKeys = false;

  const handleChange = async () => {
    const result = await updateProject(project);
    errors = result.errors || [];
    console.log("Errors", errors);
  };

  const handleDelete = async () => {
    if (confirm("Really?")) {
      const result = await deleteProject(project);
      page.redirect("/projects");
    }
  };

  const handleCSV = async () => {
      const result = await getCSV(project);
      //console.log(result);
      let csvFile = new Blob([result.data], {type: 'text/csv'});
      let url = window.URL.createObjectURL(csvFile);
      const a = document.createElement('a');
      a.setAttribute('href', url);

      a.setAttribute('download', `${projectId}-download.csv`);
      a.click();
  }

  // Handler for file upload
  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      // Process CSV
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csvText = e.target.result;
        // Parse CSV
        await importData(project, csvText);
      };
      reader.readAsText(uploadedFile);
    }
  };

  let showInputs = false;
  let importProjectId = "";
  let relTranslation = "";
  let loading = false;
  //let responseMessage = "";
  let jobId = null;
let importProgress = 0;
let importStatus = "";

  const handleImportProject = async () => {
    if (!showInputs) {
      showInputs = true;
    } else if (importProjectId.trim()) {
      // Only proceed if projectId has a value
      loading = true;
      //responseMessage = "";
      try {
        const result = await importProject(project, { oldProjectId: importProjectId, relTranslation });
        jobId = result.jobId;
        importStatus = "Processing...";

        // Poll every 5 seconds
        const interval = setInterval(async () => {
            const statusResponse = await checkImportStatus(project, jobId);
            importStatus = statusResponse.status;
            importProgress = statusResponse.progress || 0;

            if (statusResponse.status === "completed" || statusResponse.status === "failed") {
                clearInterval(interval);
                importProjectId = "";
                relTranslation = "";
                showInputs = false;
                loading = false;
            }
        }, 5000);
    } catch (error) {
        importStatus = "Error: " + error.message;
        loading = false;
    }
    }
  }


  const deleteKey = async () => {
    const result = await deletePublicKey(projectId);
    if (result && result.success) {
      hasPublicKey = false;
    }
  };

  const restoreKey = async () => {
    const result = await restorePublicKey(projectId);
    if (result && result.success) {
      hasPublicKey = true;
    }
  };

  const getQuestionOptions = () => {
    return project.questionSet.questions.reduce(
      (acc, question) => {
        console.log("Acc", acc, question);
        return [
          ...acc,
          {
            value: acc.length - 1,
            label: question.label,
          },
        ];
      },
      [{ value: -1, label: "No gating" }]
    );
  };
  let copyNetwork = 'Copy Network Link';
  let copyForms = 'Copy questions Link';

  const copyNetworkLink = async () => {
    const url = `${import.meta.env.VITE_SITE_PREFIX}${project.slug}/network`;
    navigator.clipboard.writeText(url);
    copyNetwork = 'Link Copied';
    setTimeout(() => {  copyNetwork = 'Copy Network Link'; }, 5000);
  };

  const copyFormsLink = async () => {
    const url = `${import.meta.env.VITE_SITE_PREFIX}${project.slug}`;
    navigator.clipboard.writeText(url);
    copyForms = 'Link Copied';
    setTimeout(() => {  copyForms = 'Copy questions Link'; }, 5000);
  };

  onMount(async () => {
    project = await getProject(projectId);
    if (project.errors) {
      page.redirect("/projects");
    }
    console.log("Project is", project);
    $pageTitle = project.name;

    if ($isAdmin) {
      try {
        const result = await hasKeys(projectId);
        hasPublicKey = result.hasKeys;
        loadedKeys = true;
      } catch (e) {}
    }
  });
</script>

<ProjectTabs {projectId} />

{#if project}
  <div class="panel form-styled">
    <div class="grid">
      {#if $isAdmin}
      <div class="cell">
        <div class="grid">
          <div class="cell cell--narrow text-right label">Name</div>
          <div class="cell">
            <input
              bind:value={project.name}
              on:blur={handleChange}
              type="text"
            />
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Slug</div>
          <div class="cell">
            <input
              bind:value={project.slug}
              on:blur={handleChange}
              type="text"
            />
            <a class="sitelink" href={url} target="_blank">{url}</a>
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Workshop date</div>
          <div class="cell">
            <div class="constrain">
              <EditableDate
                bind:date={project.workshopDate}
                change={handleChange}
              />
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Encryption date</div>
          <div class="cell">
            <div class="constrain">
              <EditableDate
                bind:date={project.encryptionDate}
                change={handleChange}
              />
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Clean up date</div>
          <div class="cell">
            <div class="constrain">
              <EditableDate
                bind:date={project.cleanupDate}
                change={handleChange}
              />
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Notes</div>
          <div class="cell">
            <textarea
              bind:value={project.notes}
              on:blur={handleChange}
              use:text_area_resize
            />
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Question gating</div>
          <div class="cell">
            <select bind:value={project.questionNumber} on:blur={handleChange}>
              {#each getQuestionOptions() as question}
                <option value={question.value}>{question.label}</option>
              {/each}
            </select>
          </div>
        </div>
      </div>
      {/if}
      <div class="cell">
        <a href={url} class="btn" target="_blank">View questions</a>
        <a href={url + "/network"} class="btn" target="_blank">View network</a>

                   <br /><br />

        <button class="btn" on:click="{copyFormsLink}">{copyForms}</button>
        <button class="btn" on:click="{copyNetworkLink}">{copyNetwork}</button>
                   <br /><br />

        {#if $isAdmin}
            <button class="btn is-danger" on:click={handleDelete}
              >Delete community</button
            >
            <button class="btn is-danger" on:click={handleCSV}>
                Get CSV
                </button>

            

            {#if $isAdmin && loadedKeys}
              <br /><br />
              {#if hasPublicKey}
                <button class="btn is-danger" on:click={deleteKey}
                  >Encrypt public data</button
                >
              {:else}
                <button class="btn is-danger" on:click={restoreKey}
                  >Decrypt public data</button
                >
              {/if}
            {/if}
            <!--csv import needs some styling-->
           <!--- <input type="file" accept=".csv" on:change={handleFileUpload} />-->

            <button on:click={handleImportProject} disabled={loading} class="btn is-danger">
              {loading ? "Processing..." : showInputs ? "Submit" : "Import Data"}
            </button>
            
            {#if showInputs}
              <div class="input-container">
                <input
                  type="text"
                  bind:value={importProjectId}
                  placeholder="Enter Project ID"
                  class="input"
                />
                <input
                  type="text"
                  bind:value={relTranslation}
                  placeholder="Enter relTranslation"
                  class="input"
                />
              </div>
            {/if}
        {/if}
      </div>
    </div>
  </div>
{:else}
  <Loading />
{/if}

<style lang="scss">
  .grid {
    margin-bottom: 1em;
  }
  .label {
    font-weight: bold;
    line-height: 42px;
  }
  input,
  textarea,
  select,
  .constrain {
    max-width: 300px;
  }

  a.sitelink {
    display: block;
    margin-top: 1em;
    font-weight: bold;
    font-size: 0.75em;
    color: inherit;

    &:focus {
      outline: none;
    }
  }
</style>
