import { del, get, patch, put, post} from "./api";

export const getDataForCleanup = async (id) => {
  const url = `/projects/${id}/data`;
  const data = await get(url);
  console.log("Returned data", data);
  return data;
};

const checkType = (value, type) => {
    if (Array.isArray(value)) {
        return value.includes(type);
    } else {
        return value == type;
    }
}

export const getNamedEntities = async (id: string, type = "org", exclude = []) => {
    let url = `/projects/${id}/names`;
  if (!checkType(type, "org")) {
    url = `${url}?type=${type}&exclude=${exclude}`;
    }
    console.log(url);
    const data = await get(url);
    console.log('getNamedEntities', data);
  // console.log("Returned data", data);
  return data;
};

export const updateName = async (
  projectId: string,
  nameId: string,
  value: string
) => {
  nameId = encodeURIComponent(nameId);
  let url = `/projects/${projectId}/names/${nameId}`;
  const result = await put(url, { name: value});

  return result;
};

export const updateAnswerForUser = async (
  projectId: string,
  user: string,
  set: string,
  answers: string[],
  identifier: string
) => {
  let url = `/projects/${projectId}/data/${user}`;
  const data = { set, identifier, answers };
  const result = await patch(url, data);
  return result;
};

export const deleteUserData = async (projectId: string, user: string) => {
  let url = `/projects/${projectId}/data/${user}`;
  const data = await del(url);
  return data;
};

export const deleteName = async (projectId: string, nameId: string) => {
  nameId = encodeURIComponent(nameId);
  let url = `/projects/${projectId}/names/${nameId}`;
  const data = await del(url);
  return data;
};

export const deleteAnswer = async (user: string, projectId: string, set: string, identifier: string, item, innerIndex) => {
    let url = `/projects/${projectId}/data/delete-answer`;
    
    const data = {projectId: projectId, set: set, identifier: identifier, item: item, innerIndex: innerIndex};
    console.log('del answer', data);
    const result = await post(url, {user: user, data: data});
    return result;
}

//rough will change when i know what the structure is
export const insertAnswer = async (user: string, projectId: string, set: string, identifier: string, answer: string) => {
    let url = `/projects/${projectId}/data/insert-answer`;
    const data = { user, projectId, set, identifier, answer};
    console.log(data);
    const result = await post(url, data);
    return result;
}

//this has become all editabel
export const onlyEditable = async (data, questionIdentifiers, allQuestions) => {
  try {
    console.log('only editable', data, allQuestions)
    return data.map((userData) => {
      if (!userData.data) {
        return { ...userData, data: [] };
      }


      // Function to flatten multi-dimensional arrays and track indices
      const flattenAnswersWithIndices = (answers) => {
        return answers.flatMap((answer, outer) => {
          if (Array.isArray(answer)) {
            // If it's an array, process inner array and return both indices
            return answer.map((innerAnswer, inner) => ({
              answer: innerAnswer,
              outer,
              inner,
            }));
          } else {
            // If it's not an array, return only the first index and -1 for the second
            return [{
              answer,
              outer,
              inner: -1,
            }];
          }
        });
      };

      // Flatten the nested set data structure
      const flattenedData = userData.data.flatMap(set => {
        return Object.keys(set).flatMap(setKey => {
          const questions = set[setKey];
          return Object.values(questions).flatMap(questionData => {
            // Ensure questionData is an object and has the necessary fields
            if (!questionData || !questionData.identifier || !Array.isArray(questionData.answers)) {
              return [];
            }

            // Extract identifier and answers, and flatten them with indices
            const { identifier, answers } = questionData;
            const flattenedAnswersWithIndices = flattenAnswersWithIndices(answers);
            const questionDataset = allQuestions.find(q => q.id === identifier);
            
            // Check if the identifier is in the list of questionIdentifiers
            // You can re-enable this check if needed by removing the comment
            // if (!questionIdentifiers.includes(identifier)) {
            //   return [];
            // }

            // Process the answers with the double layer index
            //console.log('returned question dataset', questionDataset, identifier);
            return flattenedAnswersWithIndices.map(({ answer, outer, inner }) => ({
              identifier,
              answer,
              setKey,
              allAnswers: answers,
              outer,        
              inner,
              type: questionDataset.dataset[0][0]     
            }));
          });
        });
      });

      return {
        ...userData,
        data: flattenedData.filter(item => item.answer !== "")
      };
    });
  } catch (error) {
    console.error('Error in onlyEditable:', error);
    return [];
  }
};




export const allErrorData = async (data, allQuestions) => {
  try {
    // Filter the input data to only include users with error data
    const usersWithErrorData = data.map((userData) => {
      if (!userData.data) {
        return { ...userData, data: [] };
      }

      // Function to flatten multi-dimensional arrays and track indices
      const flattenAnswersWithIndices = (answers) => {
        return answers.flatMap((answer, outer) => {
          if (Array.isArray(answer)) {
            // If it's an array, process inner array and return both indices
            return answer.map((innerAnswer, inner) => ({
              answer: innerAnswer,
              outer,
              inner,
            }));
          } else {
            // If it's not an array, return only the first index and -1 for the second
            return [{
              answer,
              outer,
              inner: -1,
            }];
          }
        });
      };

      // Flatten the nested set data structure
      const flattenedData = userData.data.flatMap(set => {
        return Object.keys(set).flatMap(setKey => {
          const questions = set[setKey];
          return Object.values(questions).flatMap(questionData => {
            // Ensure questionData is an object and has the necessary fields
            if (!questionData || !questionData.identifier || !Array.isArray(questionData.answers)) {
              return [];
            }

            // Extract identifier and answers, and flatten them with indices
            const { identifier, answers } = questionData;
            const flattenedAnswersWithIndices = flattenAnswersWithIndices(answers);
            const questionDataset = allQuestions.find(q => q.id === identifier);

            // Process the answers with the double layer index
            return flattenedAnswersWithIndices.map(({ answer, outer, inner }) => ({
              identifier,
              answer,
              setKey,
              allAnswers: answers,
              outer,
              inner,
              type: questionDataset.dataset[0][0],
            }));
          });
        });
      });

      // Filter error data (null, undefined, or empty answers)
      const errorData = flattenedData.filter(item => item.answer === null || item.answer === undefined);

      // Return the user data with only error data
      return { ...userData, data: errorData };
    })
    // Filter out users who have no error data
    .filter(userData => userData.data.length > 0);

    return usersWithErrorData;
  } catch (error) {
    console.error('Error in onlyEditable:', error);
    return [];
  }
};